import { Page } from "./Page";
import { Stack, Button, Sheet, Divider, CircularProgress } from "@mui/joy";
import { DownloadRounded } from "@mui/icons-material";
import { useContext, useMemo, useState } from "react";
import { ImageElement } from "./ImageElement";
import { useTemplates } from "../Hooks/useTemplates";
import {
  applyTemplate,
  downloadZip,
  extractImageDataFromCanvas,
  setupCanvas,
} from "../transform";
import JSZip from "jszip";
import { AddedFile, UploadButton } from "./UploadButton";
import { Canvas } from "./Canvas";
import { UploadStore } from "../store";

export const UploadPage = () => {
  const {
    uploads: images,
    setUploads: setImages,
    selected: selectedImage,
    setSelected: setSelectedImage,
  } = useContext(UploadStore);

  const {
    templates: { data: templates },
    defaultTemplate,
  } = useTemplates();

  const [processing, setProcessing] = useState(false);

  const handleTransform = async () => {
    setProcessing(true);
    try {
      const zip = new JSZip();
      const folder = zip.folder("images") as JSZip;
      for (let i = 0; i < images.length; i++) {
        const { url, template } = images[i];
        const config = templates?.find((el) => el.id === template);
        if (config) {
          const { elementCanvas, containerCanvas } = setupCanvas();
          await applyTemplate(
            containerCanvas,
            elementCanvas,
            url,
            config,
            1600
          );
          folder.file(
            `image-${i + 1}.jpeg`,
            extractImageDataFromCanvas(containerCanvas),
            { base64: true }
          );
        }
      }
      const compressed = await zip.generateAsync({ type: "blob" });
      downloadZip(compressed);
    } finally {
      setProcessing(false);
    }
  };

  const handleFilesAdded = (files: AddedFile[]) => {
    setImages((p) => {
      return [
        ...p,
        ...files.map(({ localUrl, ratio }) => ({
          url: localUrl,
          ratio,
          template: defaultTemplate ?? templates?.[0].id ?? "",
        })),
      ];
    });
    setSelectedImage((p) => (p === -1 ? 0 : p));
  };

  const handleDeleteImage = (index: number) => () => {
    setImages((p) => p.filter((_, i) => i !== index));
  };

  const handleChangeTemplate = (index: number) => (template: string) => {
    setImages((p) =>
      p.map((el, i) => {
        return i === index ? { ...el, template } : el;
      })
    );
  };

  const selectedTemplate = useMemo(() => {
    if (selectedImage > -1 && templates) {
      const template = templates.find(
        (el) => el.id === images[selectedImage]?.template
      );
      if (template) {
        return template;
      }
    }
  }, [selectedImage, images, templates]);

  return (
    <Page>
      <Stack direction={"row"} spacing={5} alignItems={"flex-start"}>
        <Sheet
          sx={{
            minWidth: 420,
            maxWidth: 420,
          }}
        >
          <Stack spacing={3} divider={<Divider />}>
            {images.length > 0 && (
              <Button
                size={"lg"}
                color={"success"}
                variant={"soft"}
                onClick={handleTransform}
                disabled={processing}
                startDecorator={
                  processing ? (
                    <CircularProgress size={"sm"} color={"success"} />
                  ) : (
                    <DownloadRounded />
                  )
                }
                fullWidth
              >
                {processing ? "Behandler..." : "Download"}
              </Button>
            )}
            <UploadButton
              size={"lg"}
              allowMultiple={true}
              onFilesAdded={handleFilesAdded}
            >
              Tilføj billeder
            </UploadButton>
          </Stack>
          <Stack mt={3} spacing={2}>
            {images.map((image, i) => (
              <ImageElement
                selected={selectedImage === i}
                onClick={() => setSelectedImage(i)}
                title={`#${i + 1}`}
                key={`image-${i}`}
                src={image.url}
                selectedTemplate={image.template}
                templates={templates}
                onChangeTemplate={handleChangeTemplate(i)}
                onDelete={handleDeleteImage(i)}
              />
            ))}
          </Stack>
        </Sheet>
        <Canvas
          elements={selectedTemplate?.elements ?? []}
          image={images[selectedImage]}
        />
      </Stack>
    </Page>
  );
};
