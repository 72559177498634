import {
  createContext,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
} from "react";
import { Image } from "./Hooks/useTemplates";

export type UploadedImage = Image & {
  template: string;
};

export const UploadStore = createContext<{
  uploads: UploadedImage[];
  selected: number;
  setSelected: Dispatch<SetStateAction<number>>;
  setUploads: Dispatch<SetStateAction<UploadedImage[]>>;
}>({
  selected: -1,
  uploads: [],
  setUploads: () => console.log("Added upload"),
  setSelected: () => console.log("Change selected"),
});

export const UploadProvider = ({ children }: { children: ReactNode }) => {
  const [uploads, setUploads] = useState<UploadedImage[]>([]);
  const [selected, setSelected] = useState(-1);

  const { Provider } = UploadStore;

  return (
    <Provider value={{ uploads, setUploads, selected, setSelected }}>
      {children}
    </Provider>
  );
};
