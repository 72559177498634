import { Page } from "./Page";
import {
  Button,
  CircularProgress,
  Divider,
  Option,
  Select,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { AddRounded, CheckRounded } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { ConfigElement } from "./ConfigElement";
import { Canvas } from "./Canvas";
import { useTemplates, Element, Image } from "../Hooks/useTemplates";
import { nanoid } from "nanoid";
import { AddedFile, UploadButton } from "./UploadButton";
import {
  calculateAspectRatio,
  getCorrespondingRelativeDimension,
  resize,
} from "../helpers";
import NiceModal from "@ebay/nice-modal-react";
import { AddTemplateModal } from "./AddTemplateModal";
import { useUpload } from "../Hooks/useUpload";
import * as api from "../api";

const fakeElement = {
  name: "Standard",
  image: {
    key: "hello",
    ratio: "16/9",
    url: "hiddengem.dk",
  },
  height: 100,
  width: 100,
  x: 100,
  y: 100,
};

export const ConfigPage = () => {
  const timeout = useRef<NodeJS.Timeout>();
  const canvasImageRef = useRef<HTMLImageElement>(null);
  const [dirty, setDirty] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [previewImage, setPreviewImage] = useState<Image>();
  const [pendingDeletes, setPendingDeletes] = useState<(() => Promise<void>)[]>(
    []
  );
  const [pendingUploads, setPendingUploads] = useState<
    (() => Promise<(prev: Element[]) => Element[]>)[]
  >([]);
  const [creating, setCreating] = useState(false);
  const [elements, setElements] = useState<Element[]>([fakeElement]);

  const { upload } = useUpload();

  const {
    templates: { data: templates },
    addTemplate,
    updateTemplate,
    deleteTemplate,
    updateConfig,
    defaultTemplate,
  } = useTemplates();

  useEffect(() => {
    if (
      templates &&
      templates.length > 0 &&
      defaultTemplate &&
      !selectedTemplate
    ) {
      setSelectedTemplate(defaultTemplate);
    }
  }, [defaultTemplate, templates, selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate && templates) {
      const found = templates?.find((el) => el.id === selectedTemplate);
      if (found) {
        setElements(found.elements);
        setPreviewImage({ ...found.canvas.image });
      } else {
        setElements([]);
        setPreviewImage(undefined);
      }
    }
  }, [selectedTemplate, templates]);

  const handleRePositionElement = (index: number, x: number, y: number) => {
    setElements((p) => {
      return p.map((el, i) => {
        return i === index
          ? {
              ...el,
              y,
              x,
            }
          : el;
      });
    });
    setDirty(true);
  };

  const handleChangeDimensions =
    (index: number) => (key: "width" | "height", value: number) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        const { ratio } = elements[index].image;
        setElements((p) => {
          return p.map((el, i) => {
            return i === index
              ? {
                  ...el,
                  [key]: value,
                  [key === "width"
                    ? "height"
                    : key === "height"
                    ? "width"
                    : key]: getCorrespondingRelativeDimension(
                    key,
                    value,
                    ratio
                  ),
                }
              : el;
          });
        });
        setDirty(true);
      }, 100);
    };

  const handleFilesAdded = async (files: AddedFile[]) => {
    setElements((p) => {
      return [
        ...p,
        ...files.map(({ width, height, ratio, localUrl, name }) => ({
          name,
          image: {
            url: localUrl,
            ratio,
          },
          x: 0,
          y: 0,
          ...resize(width, height, 200, 200),
        })),
      ];
    });
    const uploadAndUpdateUrl = async () => {
      const uploads = await Promise.all(files.map((el) => upload(el)));
      return (prev: Element[]) => {
        return prev.map((el, i) => {
          const index = files.findIndex((f) => f.name === el.name);
          if (index === -1) {
            return el;
          }
          const { url, key } = uploads[index];
          return {
            ...el,
            image: {
              ...el.image,
              url,
              key,
            },
          };
        });
      };
    };
    setPendingUploads((p) => [...p, uploadAndUpdateUrl]);
    setDirty(true);
  };

  const handleDeleteElement = (index: number) => () => {
    const { url } = elements[index].image;
    setPendingDeletes((p) => [...p, () => api.deleteUpload(url)]);
    setElements((p) => p.filter((_, i) => i !== index));
    setDirty(true);
  };

  const handleAddTemplate = async () => {
    try {
      const { name, image, isDefault } = await NiceModal.show<{
        name: string;
        image: AddedFile;
        isDefault: boolean;
      }>(AddTemplateModal, { mustBeChecked: !Boolean(selectedTemplate) });
      setCreating(true);
      const { width } = (
        canvasImageRef.current as HTMLImageElement
      ).getBoundingClientRect();
      const height = getCorrespondingRelativeDimension(
        "width",
        width,
        image.ratio
      );
      if (width && height) {
        const { ratio, key, url } = await upload(image);
        const id = nanoid();
        setSelectedTemplate(id);
        await addTemplate.mutateAsync({
          name,
          elements: [],
          id,
          canvas: {
            width,
            height,
            image: { key, url, ratio },
          },
        });
        if (isDefault || !defaultTemplate) {
          updateConfig.mutate({
            defaultTemplate: id,
          });
        }
      }
    } finally {
      setCreating(false);
    }
  };

  const handleSave = async () => {
    const { width, height } = (
      canvasImageRef.current as HTMLImageElement
    ).getBoundingClientRect();
    const { key, url } = previewImage ?? {};
    setDirty(false);
    const uploads = await Promise.all(pendingUploads.map((fn) => fn()));
    setPendingUploads([]);
    const updatedElements = uploads.reduce<Element[] | null>(
      (updated, updateFunc) => {
        return [...(updated ?? []), ...updateFunc(elements)];
      },
      null
    );
    if (key && url) {
      updateTemplate.mutate({
        id: selectedTemplate as string,
        elements: [...(updatedElements ?? elements)],
        canvas: {
          height,
          width,
          image: {
            key,
            url,
            ratio: calculateAspectRatio(width, height),
          },
        },
      });
    }
    await Promise.all(pendingDeletes.map((fn) => fn()));
    setPendingDeletes([]);
  };

  const handleChangePreview = async (file: AddedFile) => {
    const updateImage = async () => {
      const { ratio, key, url } = await upload(file);
      const { width } = (
        canvasImageRef.current as HTMLImageElement
      ).getBoundingClientRect();
      const height = getCorrespondingRelativeDimension("width", width, ratio);
      return updateTemplate.mutateAsync({
        id: selectedTemplate as string,
        ...(dirty && {
          elements: [...elements],
        }),
        canvas: {
          height,
          width,
          image: {
            key,
            url,
            ratio: calculateAspectRatio(width, height),
          },
        },
      });
    };
    await Promise.all([
      previewImage?.url && api.deleteUpload(previewImage.url),
      updateImage(),
      Promise.all(pendingDeletes.map((fn) => fn())).then(() =>
        setPendingDeletes([])
      ),
    ]);
  };

  const handleSetAsDefault = async (id: string | null) => {
    setSelectedTemplate(id);
    return updateConfig.mutateAsync({ defaultTemplate: id });
  };

  const handleDeleteTemplate = () => {
    const { id, canvas, elements } =
      templates?.find((el) => el.id === selectedTemplate) ?? {};
    if (id && templates) {
      const [first] = templates.filter((el) => el.id !== id);
      return Promise.all([
        handleSetAsDefault(first?.id ?? null),
        canvas && api.deleteUpload(canvas.image.url),
        Promise.all(
          elements?.map((el) => api.deleteUpload(el.image.url)) ?? []
        ),
        deleteTemplate.mutateAsync(id),
      ]);
    }
  };

  const handleAlignElement =
    (index: number) => (direction: "horizontal" | "vertical") => () => {
      if (canvasImageRef.current) {
        const { left, top, width, height } =
          canvasImageRef.current?.getBoundingClientRect() ?? {};
        if (width !== undefined && height !== undefined && elements[index]) {
          if (direction === "horizontal") {
            const { width: w, y } = elements[index];
            const newX = (width - w) / 2;
            handleRePositionElement(index, newX, y);
          }
          if (direction === "vertical") {
            const { height: h, x } = elements[index];
            const newY = (height - h) / 2;
            handleRePositionElement(index, x, newY);
          }
        }
      }
    };

  return (
    <Page>
      <Stack direction={"row"} spacing={5} alignItems={"flex-start"}>
        <Sheet
          sx={{
            minWidth: 420,
            maxWidth: 420,
          }}
        >
          <Sheet>
            <Stack
              mb={1.5}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography level={"h6"}>Vælg skabelon</Typography>
              <Button
                variant={"soft"}
                startDecorator={
                  creating ? <CircularProgress size={"sm"} /> : <AddRounded />
                }
                disabled={creating}
                onClick={handleAddTemplate}
              >
                {creating ? "Opretter..." : "Opret skabelon"}
              </Button>
            </Stack>
            {templates && templates.length > 0 && (
              <>
                <Select
                  value={selectedTemplate}
                  size={"lg"}
                  onChange={(_, value) => setSelectedTemplate(value as string)}
                >
                  {templates?.map((template) => (
                    <Option
                      key={template.id}
                      value={template.id}
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography>{template.name}</Typography>
                    </Option>
                  ))}
                </Select>
                <Stack
                  mt={1}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Button
                    color={"danger"}
                    variant={"plain"}
                    size={"sm"}
                    onClick={handleDeleteTemplate}
                  >
                    Slet skabelon
                  </Button>
                  {selectedTemplate !== defaultTemplate && (
                    <Button
                      variant={"plain"}
                      size={"sm"}
                      color={"info"}
                      onClick={() => handleSetAsDefault(selectedTemplate)}
                    >
                      Brug som standard
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </Sheet>
          <Divider sx={{ mt: 1.5, mb: 1.5 }} />
          <Stack mt={3} spacing={2}>
            {elements.map(({ name, height, width, image }, i) => (
              <ConfigElement
                title={name}
                onChange={handleChangeDimensions(i)}
                onDelete={handleDeleteElement(i)}
                onClickAlign={handleAlignElement(i)}
                key={`config-element-${i}`}
                src={image?.url}
                height={height}
                width={width}
              />
            ))}
          </Stack>
          {selectedTemplate && templates && templates.length > 0 && (
            <Stack direction={"row"} mt={2} justifyContent={"space-between"}>
              <UploadButton onFilesAdded={handleFilesAdded}>
                Tilføj billede
              </UploadButton>
              {dirty && (
                <Button
                  color={"success"}
                  variant={"soft"}
                  startDecorator={<CheckRounded />}
                  onClick={handleSave}
                >
                  Gem ændringer
                </Button>
              )}
            </Stack>
          )}
        </Sheet>
        <Canvas
          onChangeImage={previewImage?.url ? handleChangePreview : undefined}
          ref={canvasImageRef}
          elements={elements}
          image={previewImage}
          onRePositionElement={handleRePositionElement}
        />
      </Stack>
    </Page>
  );
};
