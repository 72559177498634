const gcd = (a: number, b: number): number => {
  return b === 0 ? a : gcd(b, a % b);
};

export const calculateAspectRatio = (width: number, height: number) => {
  const r = gcd(width, height);
  return `${width / r}/${height / r}`;
};

export const getCorrespondingRelativeDimension = (
  knownType: "width" | "height",
  knownDimension: number,
  ratio: string
) => {
  const [wR, hR] = ratio.split("/").map((el) => parseInt(el));
  if (knownType === "width") {
    return Math.floor((knownDimension / wR) * hR);
  } else {
    return Math.floor((knownDimension / hR) * wR);
  }
};

export const resize = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};
