import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Modal,
  ModalDialog,
  Typography,
  Stack,
  TextField,
  Button,
  Checkbox,
} from "@mui/joy";
import { AddedFile, UploadButton } from "./UploadButton";
import { ImageContainer } from "./ImageContainer";
import { useState } from "react";

export const AddTemplateModal = NiceModal.create(({ mustBeChecked = false }: { mustBeChecked?: boolean }) => {
  const modal = useModal();

  const [checked, setChecked] = useState(false);
  const [image, setImage] = useState<AddedFile>();

  const handleSubmit = (name: string) => {
    if (image && name) {
      modal.resolve({ name, image, isDefault: checked });
      modal.remove();
    }
  };

  const handleClose = () => {
    modal.remove();
  };

  return (
    <Modal open={modal.visible} onClose={handleClose}>
      <ModalDialog
        aria-labelledby="add-template-dialog-title"
        aria-describedby="add-template-dialog-description"
        sx={{
          maxWidth: 500,
          borderRadius: "md",
          p: 3,
          background: "white",
          boxShadow: "lg",
        }}
      >
        <Typography
          id="add-template-dialog-title"
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
        >
          Opret ny skabelon
        </Typography>
        <Typography
          id="add-template-dialog-description"
          mt={0.5}
          mb={2}
          textColor="text.tertiary"
        >
          Giv den et navn og upload et preview billede
        </Typography>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const data = new FormData(event.target as HTMLFormElement);
            handleSubmit(data.get("name") as string);
          }}
        >
          <Stack spacing={3}>
            <TextField label="Navn" name={"name"} autoFocus required />
            <ImageContainer
              src={image?.localUrl}
              ratio={image?.ratio}
              renderWhenNoImage={() => (
                <UploadButton sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }} onFilesAdded={(files) => setImage(files[0])}>
                  Vælg billede
                </UploadButton>
              )}
            />

            <Checkbox
              defaultChecked={mustBeChecked}
              disabled={mustBeChecked}
              onChange={(evt) => setChecked(evt.target.checked)}
              name={"check"}
              label="Brug som standard på alle billeder"
            />
            <Button type="submit">Opret</Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
});
