import {
  Card,
  IconButton,
  Stack,
  Sheet,
  Typography,
  TextField,
  Tooltip,
} from "@mui/joy";
import {
  AlignHorizontalCenter,
  AlignVerticalCenter,
  DeleteRounded,
} from "@mui/icons-material";

export const ConfigElement = ({
  title,
  src,
  height,
  width,
  onChange,
  onDelete,
  onClickAlign,
}: {
  title: string;
  width: number;
  height: number;
  onChange: (key: "width" | "height", value: number) => void;
  onDelete: () => void;
  onClickAlign: (key: "horizontal" | "vertical") => () => void;
  src: string;
}) => {
  const handleDelete = () => {
    if (window.confirm("Er du sikker på at slette?")) {
      onDelete();
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Sheet
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Sheet
          component={"img"}
          variant={"outlined"}
          src={src}
          height={80}
          width={80}
          sx={{
            objectFit: "contain",
            borderRadius: 8,
          }}
          alt={"Thumbnail"}
        />
        <Stack ml={1.5} justifyContent={"center"} flexGrow={1}>
          <Typography
            level={"h6"}
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: 210,
            }}
          >
            {title}
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <TextField
              type={"number"}
              sx={{ maxWidth: 72 }}
              size={"sm"}
              value={width}
              label={"Bredde"}
              onChange={(e) => onChange("width", parseInt(e.target.value))}
              slotProps={{
                input: {
                  slotProps: {
                    input: {
                      min: 0,
                    },
                  },
                },
              }}
            />
            <TextField
              type={"number"}
              size={"sm"}
              value={height}
              sx={{ maxWidth: 72 }}
              label={"Højde"}
              onChange={(e) => onChange("height", parseInt(e.target.value))}
              slotProps={{
                input: {
                  slotProps: {
                    input: {
                      min: 0,
                    },
                  },
                },
              }}
            />
            <Stack direction={"row"} spacing={1} alignItems={"flex-end"}>
              <Tooltip title={"Centrer horisontalt"}>
                <IconButton size={"sm"} onClick={onClickAlign("horizontal")}>
                  <AlignHorizontalCenter fontSize={"small"} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Centrer vertikalt"}>
                <IconButton size={"sm"} onClick={onClickAlign("vertical")}>
                  <AlignVerticalCenter fontSize={"small"} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
        <IconButton color={"danger"} size={"sm"} onClick={handleDelete}>
          <DeleteRounded />
        </IconButton>
      </Sheet>
    </Card>
  );
};
