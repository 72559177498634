import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as api from "../api";
import { useState } from "react";

export type Image = {
  key?: string;
  ratio: string;
  url: string;
};

export type Config = {
  defaultTemplate: string | null;
  logoUrl: string;
  previewImage: Image;
  templates: Template[];
};

export type Element = {
  name: string;
  image: Image;
  height: number;
  width: number;
  x: number;
  y: number;
};

export type Template = {
  id: string;
  name: string;
  canvas: {
    width: number;
    height: number;
    image: Image;
  };
  elements: Element[];
};

export const useTemplates = () => {
  const [defaultTemplate, setDefaultTemplate] = useState<string | null>();
  const [logoUrl, setLogoUrl] = useState<string>();

  const queryClient = useQueryClient();

  const QueryKey = ["templates"];

  const templates = useQuery(
    QueryKey,
    async () => {
      const { templates, defaultTemplate, logoUrl } =
        await api.getConfig<Config>();
      setDefaultTemplate(defaultTemplate);
      setLogoUrl(logoUrl);
      return templates;
    },
    {
      enabled: true,
    }
  );

  const addTemplate = useMutation(
    async (_: Template) => {
      const previousTemplates =
        queryClient.getQueryData<Template[]>(QueryKey) ?? [];
      await api.updateConfig({
        templates: previousTemplates,
      });
    },
    {
      onMutate: async ({ id, ...payload }) => {
        await queryClient.cancelQueries(QueryKey);

        const previousTemplates =
          queryClient.getQueryData<Template[]>(QueryKey);

        queryClient.setQueryData<Template[]>(QueryKey, (prev) => {
          return [...(prev ?? []), { id, ...payload }];
        });

        return { previousTemplates };
      },
      onError: (err, variables, context: any) => {
        if (context?.previousTemplates) {
          queryClient.setQueryData<Template[]>(
            QueryKey,
            context.previousTemplates
          );
        }
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: QueryKey });
      },
    }
  );

  const updateTemplate = useMutation(
    async (data: Partial<Template>) => {
      const previousTemplates =
        queryClient.getQueryData<Template[]>(QueryKey) ?? [];
      const updated = previousTemplates.map((el) => {
        return el.id === data.id
          ? {
              ...el,
              ...data,
            }
          : el;
      });
      await api.updateConfig({ templates: [...updated] });
    },
    {
      onMutate: async ({ id, ...payload }) => {
        await queryClient.cancelQueries(QueryKey);

        const previousTemplates =
          queryClient.getQueryData<Template[]>(QueryKey);

        queryClient.setQueryData<Template[]>(QueryKey, (prev) => {
          return prev?.map((template) => {
            return template.id === id
              ? {
                  ...template,
                  ...payload,
                }
              : template;
          });
        });

        return { previousTemplates };
      },
      onError: (err, variables, context: any) => {
        if (context?.previousTemplates) {
          queryClient.setQueryData<Template[]>(
            QueryKey,
            context.previousTemplates
          );
        }
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: QueryKey });
      },
    }
  );

  const deleteTemplate = useMutation(
    async (id: Template["id"]) => {
      const previousTemplates =
        queryClient.getQueryData<Template[]>(QueryKey) ?? [];
      const updated = previousTemplates.filter((el) => el.id !== id);
      await api.updateConfig({ templates: [...updated] });
    },
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(QueryKey);

        const previousTemplates =
          queryClient.getQueryData<Template[]>(QueryKey);

        queryClient.setQueryData<Template[]>(QueryKey, (prev) => {
          return prev?.filter((template) => template.id !== id);
        });

        return { previousTemplates };
      },
      onError: (err, variables, context: any) => {
        if (context?.previousTemplates) {
          queryClient.setQueryData<Template[]>(
            QueryKey,
            context.previousTemplates
          );
        }
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: QueryKey });
      },
    }
  );

  const updateConfig = useMutation(
    async (data: Partial<Config>) => {
      const currentTemplates = queryClient.getQueryData<Template[]>(QueryKey);
      await api.updateConfig({
        templates: [...(currentTemplates ?? [])],
        defaultTemplate,
        logoUrl,
        ...data,
      });
    },
    {
      onMutate: async (payload) => {
        const { defaultTemplate: template } = payload;
        const previousTemplate = defaultTemplate;
        if (template) {
          setDefaultTemplate(template);
        }
        return { previousTemplate };
      },
      onError: (err, variables, context: any) => {
        if (context?.previousTemplate) {
          setDefaultTemplate(context.previousTemplate);
        }
      },

      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: QueryKey });
      },
    }
  );

  return {
    logoUrl,
    templates,
    addTemplate,
    updateTemplate,
    updateConfig,
    defaultTemplate,
    deleteTemplate,
  };
};
