import { Button, CircularProgress, Sheet } from "@mui/joy";
import { ImageContainer } from "./ImageContainer";
import { Element, Image } from "../Hooks/useTemplates";
import {
  createRef,
  forwardRef,
  MouseEventHandler,
  useRef,
  useState,
} from "react";
import { AddedFile, UploadButton } from "./UploadButton";

type DraggableElement = Pick<
  Element,
  "image" | "height" | "width" | "x" | "y"
> & {
  selected?: boolean;
};

export const Canvas = forwardRef<
  HTMLImageElement,
  {
    onChangeImage?: (file: AddedFile) => Promise<void>;
    image?: Image;
    elements: DraggableElement[];
    onRePositionElement?: (index: number, x: number, y: number) => void;
    onContainerImageLoaded?: (width: number, height: number) => void;
  }
>(
  (
    {
      image,
      elements,
      onContainerImageLoaded,
      onChangeImage,
      onRePositionElement,
    },
    ref
  ) => {
    const refs = elements.map(() => createRef<HTMLDivElement>());
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const currentPositionX = useRef<null | number>(null);
    const currentPositionY = useRef<null | number>(null);
    const [canvasRect, setCanvasRect] = useState<null | {
      h: number;
      w: number;
      x: number;
      y: number;
    }>();
    const [clickOffset, setClickOffset] = useState<null | [number, number]>();
    const [isDown, setIsDown] = useState(false);
    const [currentTarget, setCurrentTarget] = useState<null | number>(null);
    const [changingImage, setChangingImage] = useState(false);

    const handleMouseDown: MouseEventHandler = (e) => {
      setIsDown(true);
      if (canvasRef.current) {
        const { left, top, width, height } =
          canvasRef.current?.getBoundingClientRect() ?? {};
        if (left !== undefined && top !== undefined) {
          const x = e.clientX - left;
          const y = e.clientY - top;
          setClickOffset([x, y]);
          setCanvasRect({ x: left, y: top, w: width, h: height });
          setCurrentTarget(getElementHitIndex(x, y));
        }
      }
    };

    const getElementHitIndex = (x: number, y: number) => {
      for (let i = 0; i < elements.length; i++) {
        const box = elements[i];
        if (
          x >= box.x &&
          x <= box.x + box.width &&
          y >= box.y &&
          y <= box.y + box.height
        ) {
          return i;
        }
      }
      return null;
    };

    const handleMouseMove: MouseEventHandler = (e) => {
      if (isDown && canvasRect && clickOffset && currentTarget !== null) {
        const target = refs[currentTarget];
        if (target.current) {
          const { h: cH, w: cW, x: cX, y: cY } = canvasRect;
          const { height: h, width: w, x, y } = elements[currentTarget];
          const left = e.clientX - cX - clickOffset[0] + x;
          const top = e.clientY - cY - clickOffset[1] + y;
          if (left >= 0 && left <= cW - w) {
            target.current.style.left = `${left}px`;
            currentPositionX.current = left;
          }
          if (top >= 0 && top <= cH - h) {
            target.current.style.top = `${top}px`;
            currentPositionY.current = top;
          }
        }
      }
    };

    const resetStyles = (index: number) => {
      const target = refs[index];
      if (target.current) {
        target.current.style.removeProperty("left");
        target.current.style.removeProperty("top");
      }
    };

    const handleMouseUp: MouseEventHandler = (e) => {
      setIsDown(false);
      if (
        currentPositionX.current !== null &&
        currentPositionY.current !== null &&
        currentTarget !== null
      ) {
        onRePositionElement?.(
          currentTarget,
          currentPositionX.current as number,
          currentPositionY.current as number
        );
        resetStyles(currentTarget);
      }
      setCurrentTarget(null);
    };

    const handleChangeImage = async (files: AddedFile[]) => {
      setChangingImage(true);
      try {
        await onChangeImage?.(files[0]);
      } finally {
        setChangingImage(false);
      }
    };

    return (
      <Sheet sx={{ position: "relative", flexGrow: 1 }} ref={canvasRef}>
        {onChangeImage && (
          <UploadButton
            startDecorator={
              changingImage ? <CircularProgress size={"sm"} /> : null
            }
            onFilesAdded={handleChangeImage}
            sx={{ position: "absolute", zIndex: 20, top: 8, right: 8 }}
          >
            {changingImage ? "Uploader..." : "Skift"}
          </UploadButton>
        )}

        <Sheet
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          component={Button}
          variant={"outlined"}
          sx={{
            "&:hover, &:active": {
              backgroundColor: "transparent",
            },
            pointerEvents: image && onRePositionElement ? "initial" : "none",
            position: "absolute",
            width: "100%",
            cursor: "initial",
            height: "100%",
            zIndex: 10,
          }}
        >
          {elements.map((el, i) => (
            <Draggable
              key={`draggable-${i}`}
              ref={refs[i]}
              {...el}
              selected={i === currentTarget}
            />
          ))}
        </Sheet>

        <ImageContainer
          sx={{
            borderRadius: 8,
          }}
          ref={ref}
          src={image?.url}
          ratio={image?.ratio}
          onContainerImageLoaded={onContainerImageLoaded}
        />
      </Sheet>
    );
  }
);

const Draggable = forwardRef<HTMLDivElement, DraggableElement>(
  ({ width, height, x, y, image, selected = false }, ref) => {
    return (
      <Sheet
        ref={ref}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
            border: "2px solid blue",
          },
          cursor: "pointer",
          position: "absolute",
          borderRadius: 8,
          width,
          height,
          border: "2px solid transparent",
          overflow: "hidden",
          background: `url(${image.url}) center no-repeat`,
          backgroundSize: "contain",
          top: y,
          left: x,
          ...(selected && {
            border: "2px solid blue",
          }),
        }}
      />
    );
  }
);
