import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import { extendTheme } from "@mui/joy";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import NiceModal from "@ebay/nice-modal-react";
import { UploadProvider } from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

const theme = extendTheme({
  components: {
    JoyDivider: {
      styleOverrides: {
        root: {
          background: "rgba(100,100,100,0.1)",
        },
      },
    },
    JoySheet: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...((ownerState.variant === "plain" ||
            ownerState.variant === "outlined") && {
            background: "transparent",
          }),
        }),
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: "#F6F6F6",
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <UploadProvider>
          <NiceModal.Provider>
            <App />
          </NiceModal.Provider>
        </UploadProvider>
      </QueryClientProvider>
    </CssVarsProvider>
  </React.StrictMode>
);
