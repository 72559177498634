import {
  Card,
  IconButton,
  Select,
  Sheet,
  Option,
  Typography,
  Button,
  ButtonProps,
} from "@mui/joy";
import { DeleteRounded } from "@mui/icons-material";
import { Template } from "../Hooks/useTemplates";

export const ImageElement = ({
  title,
  src,
  selectedTemplate,
  templates,
  onDelete,
  onChangeTemplate,
  onClick,
  selected = false,
}: {
  onClick: ButtonProps["onClick"];
  onDelete: () => void;
  onChangeTemplate: (template: string) => void;
  title: string;
  src: string;
  selectedTemplate: string;
  templates?: Template[];
  selected: boolean;
}) => {
  return (
    <Card
      component={Button}
      sx={{
        border: "2px solid transparent",
        ...(selected && {
          border: "2px solid blue",
        }),
        "&:hover, &:active": {
          background: "transparent",
        },
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={onClick}
    >
      <Sheet
        sx={{
          display: "flex",
        }}
      >
        <Sheet
          component={"img"}
          variant={"outlined"}
          src={src}
          width={90}
          sx={{
            objectFit: "cover",
            borderRadius: 8,
          }}
          alt={"Thumbnail"}
        />
        <Sheet sx={{ ml: 1.5 }}>
          <Typography level={"h6"} textAlign={"left"}>
            {title}
          </Typography>
          <Select
            value={selectedTemplate}
            onChange={(_, value) => onChangeTemplate(value as string)}
          >
            {templates?.map((template) => (
              <Option key={template.id} value={template.id}>
                {template.name}
              </Option>
            ))}
          </Select>
        </Sheet>
      </Sheet>
      <IconButton color={"danger"} size={"sm"} onClick={onDelete}>
        <DeleteRounded />
      </IconButton>
    </Card>
  );
};
