import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ConfigPage } from "./Components/ConfigPage";
import { UploadPage } from "./Components/UploadPage";

const App = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME ?? ""}>
      <Routes>
        <Route path={"/templates"} element={<ConfigPage />} />
        <Route path={"/upload"} element={<UploadPage />} />
        <Route path={"*"} index element={<Navigate to={"/upload"} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
