import { useState } from "react";
import { getSignedUrl, uploadPreviewImage } from "../api";
import { AddedFile } from "../Components/UploadButton";

export const useUpload = () => {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const upload = async (item: AddedFile) => {
    setUploading(true);
    try {
      const { signedRequest, url, key } = await getSignedUrl(item.file.type);
      await uploadPreviewImage(
        item.file,
        signedRequest,
        setProgress,
        item.file.type
      );
      return {
        key,
        url,
        ratio: item.ratio,
      };
    } finally {
      setUploading(false);
    }
  };
  return {
    uploading,
    progress,
    upload,
  };
};
