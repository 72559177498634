import { ChangeEventHandler, useRef } from "react";
import { Button, ButtonProps } from "@mui/joy";
import { AddRounded } from "@mui/icons-material";
import { calculateAspectRatio } from "../helpers";

export type AddedFile = {
  localUrl: string;
  file: File;
  width: number;
  height: number;
  name: string;
  ratio: string;
};

export const UploadButton = ({
  onFilesAdded,
  children,
  allowMultiple = false,
  startDecorator = <AddRounded />,
  ...props
}: ButtonProps & {
  onFilesAdded: (files: AddedFile[]) => void;
  allowMultiple?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleAddImage = () => {
    inputRef.current?.click();
  };
  const handleChange: ChangeEventHandler<HTMLInputElement> = async (evt) => {
    let files = [];
    for (const file of Array.from(evt.target.files ?? [])) {
      const localUrl = URL.createObjectURL(file);
      const { width, height } = await getDimensions(localUrl);
      files.push({
        localUrl,
        name: file.name,
        file,
        height,
        width,
        ratio: calculateAspectRatio(width, height),
      });
    }
    onFilesAdded(files);
  };

  const getDimensions = (
    url: string
  ): Promise<{ width: number; height: number }> => {
    return new Promise((res) => {
      const img = new Image();
      img.onload = () => {
        res({ width: img.width, height: img.height });
      };
      img.src = url;
    });
  };

  return (
    <>
      <Button
        variant={"soft"}
        onClick={handleAddImage}
        startDecorator={startDecorator}
        {...props}
      >
        {children}
      </Button>
      <input
        multiple={allowMultiple}
        ref={inputRef}
        type={"file"}
        onChange={handleChange}
        hidden
      />
    </>
  );
};
