import { Button, Sheet, Stack } from "@mui/joy";
import { ComponentPropsWithoutRef } from "react";
import { PhotoCameraRounded, SettingsRounded } from "@mui/icons-material";
import { useMatch, useNavigate } from "react-router-dom";

export const Page = ({ children }: ComponentPropsWithoutRef<any>) => {
  const navigate = useNavigate();
  const {
    params: { key },
  } = useMatch("/:key") ?? { params: {} };
  return (
    <Sheet>
      <Stack
        component={"header"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={3}
        boxShadow={"4px 0 12px 0 rgba(0,0,0,0.12)"}
        zIndex={100}
        sx={{
          background: "white",
        }}
      >
        <a href={"/"}>
          <img height={40} alt={"Logo"} src={"./logo.png"} />
        </a>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant={key === "upload" ? "soft" : "plain"}
            onClick={() => navigate("/upload")}
            startDecorator={<PhotoCameraRounded />}
          >
            Upload billeder
          </Button>
          <Button
            variant={key === "templates" ? "soft" : "plain"}
            onClick={() => navigate("/templates")}
            startDecorator={<SettingsRounded />}
          >
            Skabeloner
          </Button>
        </Stack>
      </Stack>
      <Sheet
        sx={{
          maxWidth: 1600,
          mr: "auto",
          ml: "auto",
          p: 8,
          mt: 1,
        }}
      >
        {children}
      </Sheet>
    </Sheet>
  );
};
