import { calculateAspectRatio, resize } from "./helpers";
import { Template } from "./Hooks/useTemplates";

const drawImage = async (
  canvas: HTMLCanvasElement,
  src: string,
  maxWidth: number,
  maxHeight: number
) => {
  return new Promise((res) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const { width, height } = resize(
        img.width,
        img.height,
        maxWidth,
        maxHeight
      );
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);
      }
      res("Success");
    };
    img.src = prepareUrl(src);
  });
};

const prepareUrl = (url: string) => {
  return url.startsWith('http') ? `${url}?${Number(new Date())}` : url
}

const getRelativeSize = (
  sourceWidth: number,
  sourceHeight: number,
  containerWidth: number,
  containerHeight: number,
  targetWidth: number
) => {
  const [wR, hR] = calculateAspectRatio(containerWidth, containerHeight).split(
    "/"
  );

  const width = Math.floor(targetWidth / (containerWidth / sourceWidth));
  const height = Math.floor(
    ((targetWidth / parseInt(wR)) * parseInt(hR)) /
      (containerHeight / sourceHeight)
  );
  return { width, height };
};

export const applyTemplate = async (
  canvasContainer: HTMLCanvasElement,
  canvasElement: HTMLCanvasElement,
  imageSrc: string,
  template: Template,
  targetWidth: number
) => {
  try {
    const photon = await import("@silvia-odwyer/photon");
    await drawImage(canvasContainer, imageSrc, targetWidth, targetWidth);
    const originalCtx = canvasContainer.getContext(
      "2d"
    ) as CanvasRenderingContext2D;

    for (const el of template.elements) {
      const { width, height } = getRelativeSize(
        el.width,
        el.height,
        template.canvas.width,
        template.canvas.height,
        targetWidth
      );

      await drawImage(canvasElement, el.image.url, width, height);
      const elementCtx = canvasElement.getContext(
        "2d"
      ) as CanvasRenderingContext2D;

      const originalPhotonImage = photon.open_image(
        canvasContainer,
        originalCtx
      );
      const elementPhotonImage = photon.open_image(canvasElement, elementCtx);

      const { width: x, height: y } = getRelativeSize(
        el.x,
        el.y,
        template.canvas.width,
        template.canvas.height,
        targetWidth
      );

      photon.watermark(originalPhotonImage, elementPhotonImage, x, y);
      photon.putImageData(canvasContainer, originalCtx, originalPhotonImage);
    }
  } catch (err) {
    console.error("Error processing image");
  }
};

export const downloadImage = (canvas: HTMLCanvasElement) => {
  const link = document.createElement("a");
  link.download = "image.jpeg";
  link.href = canvas.toDataURL("image/jpeg", 1);
  link.click();
};

export const downloadZip = (blob: Blob) => {
  const link = document.createElement("a");
  link.download = "download.zip";
  link.href = URL.createObjectURL(blob);
  link.click();
};

export const extractImageDataFromCanvas = (canvas: HTMLCanvasElement) => {
  const dataUrl = canvas.toDataURL('image/jpeg', 1);
  return dataUrl.substring(dataUrl.indexOf(",") + 1);
};

export const setupCanvas = () => {
  const elementCanvas = document.createElement("canvas");
  elementCanvas.hidden = true;
  const containerCanvas = document.createElement("canvas");
  containerCanvas.hidden = true;
  return {
    elementCanvas,
    containerCanvas,
  };
};
