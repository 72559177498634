import axios from "axios";
import { Config } from "./Hooks/useTemplates";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL ?? "/admin",
});

export const getConfig = async <T>() => {
  const { data } = await api.get<T>("/logo-machine/config");
  return data;
};

export const updateConfig = async <T>(payload: Partial<Config>) => {
  const { data } = await api.put<T>("/logo-machine/config", {
    ...payload,
  });
  return data;
};

export const uploadPreviewImage = async (
  file: File,
  presignedUrl: string,
  renderProgress?: (progress: number) => void,
  mime: File["type"] = "application/x-www-form-urlencoded"
) => {
  if (!presignedUrl) {
    throw new Error("No presigned url present.");
  }

  const { data } = await api.put(presignedUrl, file, {
    headers: {
      "Content-Type": mime,
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
      );
      renderProgress?.(percentCompleted);
    },
  });

  return data;
};

export const getSignedUrl = async (mime: string) => {
  const { data } = await api.get<{
    signedRequest: string;
    url: string;
    s3Url: string;
    key: string;
  }>("/upload/signed", {
    params: {
      mime,
      type: "logo-machine",
    },
  });
  const { s3Url, url, ...rest } = data;
  return {
    ...rest,
    url: s3Url
  };

};

export const deleteUpload = async (src: string) => {
  await api.post("/upload/delete", null, {
    params: {
      src,
    },
  });
};
