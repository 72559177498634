import { ReactNode } from "react";
import { AspectRatio, AspectRatioProps, Sheet } from "@mui/joy";
import { forwardRef } from "react";

export const ImageContainer = forwardRef<
  HTMLImageElement,
  AspectRatioProps & {
    src?: string;
    renderWhenNoImage?: () => ReactNode;
    onContainerImageLoaded?: (width: number, height: number) => void;
  }
>(
  (
    { src, sx = {}, renderWhenNoImage, onContainerImageLoaded, ...props },
    ref
  ) => {
    return (
      <AspectRatio
        sx={{
          ...sx,
          flexGrow: 1,
          boxShadow: "0 0 12px 0 rgba(0,0,0,0.12)",
          display: "block",
        }}
        ref={ref}
        {...props}
      >
        {src && (
          <Sheet
            component={"img"}
            sx={{
              display: "block",
            }}
            width={"100%"}
            src={src}
            alt={"Preview"}
          />
        )}

        {!src && renderWhenNoImage && renderWhenNoImage()}
      </AspectRatio>
    );
  }
);
